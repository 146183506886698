/**
 * Based on their sample: https://docs.stripe.com/payments/accept-a-payment-deferred?platform=web&type=payment#web-fulfillment
 */

const stripeStyles = require('Scripts/donate-form/stripe-styles');

export default class StripePaymentElement {
    
    // 'donate' is an instance of 'donate-form/donate.js'
    constructor(donate, opts) {
        console.log('StripePaymentElement.constructor');

        this.donate = donate;

        this.mounted = false

        if(!this.shouldInitialise()) {
            console.log('Not initialised, feature not enabled.');
            return
        }

        this.customOnFocus = opts.onFocus || (() => {});

        this.elements = this.createElements();
        this.paymentElement = this.createPaymentElement();

        this.bindEvents();
        this.mountPaymentElement();
    }

    shouldInitialise() {
        const { directDebitDisabled } = this.donate.ui.paymentElementContainer.data();
        return !directDebitDisabled;
    }

    validate() {
        return this.elements.submit();
    }

    createElements() {
        console.log('StripePaymentElement.createElements');

        return this.donate.stripe.elements({
            mode: 'payment',
            currency: 'gbp',
            amount: 100,
            paymentMethodTypes: ['bacs_debit'],
            fonts: stripeStyles.fonts,
            appearance: stripeStyles.paymentElementStyles,
            setup_future_usage: 'off_session'
        });
    }

    createPaymentElement() {
        console.log('StripePaymentElement.createPaymentElement');

        const options = {
            // This does nothing, as there's only 1 payment method.
            layout: 'tabs',
            // These shouldn't be shown, but make doubly sure they can't be
            wallets: {
                applePay: 'never',
                googlePay: 'never'
            },
            fields: {
                billingDetails: {
                    // We collect these in other parts of the process
                    name: 'never',
                    email: 'never',
                    address: 'never',
                    // We only collect this in certain circumstances
                    phone: 'auto'
                }
            }
        };

        return this.elements.create('payment', options);
    }

    makePayment() {
        return this.createPaymentIntent()
            .then(response => this.confirmPayment(response))
            .then(response => this.submitPayment(response))
            .catch(error => {
                this.handleFatalError(error);
            });
    }

    confirmPayment(response) {
        if(!response.success) {
            throw new Error('Payment intent failed to be created');
        }

        // These have been collected via other parts of the donation process,
        // so we need to manually submit them when confirming the payment.
        const paymentMethodData = this.donate.extractBillingDetails();

        // We don't collect address line 2, but it's required for this, so just set it to a null value.
        // todo: Can we just exclude this from the options like phone number?
        paymentMethodData.billing_details.address.line2 = null;

        return this.donate.stripe.confirmPayment({
            elements: this.elements,
            clientSecret: response.paymentIntentClientSecret,
            redirect: 'if_required',
            confirmParams: {
                payment_method_data: paymentMethodData,
                return_url: window.location.href
            }
        });
    }

    submitPayment(response) {
        if(response.error) {
            const { code } = response.error;

            // If the user clicks "Modify details" in the Direct Debit modal, it's 
            // actually treated as an error, so we need to handle this case
            if(code === 'modify_bacs_debit_bank_details') {
                return Promise.resolve({
                    amendDirectDebitDetails: true
                });
            }

            // Any other error should be treated as fatal
            return Promise.reject(response.error);
        }

        // Otherwise carry on with payment submission
        return this.donate.submitPayment(response);
    }

    createPaymentIntent() {
        // Set the payment method type
        this.donate.ui.paymentMethodField.val(this.donate.getUsedPaymentMethod());

        const formData = new FormData(this.donate.ui.forms.donation[0])
        return fetch('/donate/prepare', {
			method: 'POST',
			body: formData
		}).then(response => response.json());
    }

    mountPaymentElement() {
        console.log('StripePaymentElement.mountPaymentElement');
        this.paymentElement.mount(this.donate.ui.paymentElementContainer[0]);
    }

    bindEvents() {
        this.paymentElement.on('ready', this.onReady.bind(this));
        this.paymentElement.on('change', this.onChange.bind(this));
        this.paymentElement.on('focus', this.onFocus.bind(this));
        this.paymentElement.on('loaderror', this.onLoadError.bind(this));
        this.paymentElement.on('loaderstart', this.onLoaderStart.bind(this));
    }

    handleFatalError(error) {
        this.donate.showDeclinedScreen(error);
        this.paymentElement.destroy();
    }

    onReady(e) {
        console.log('StripePaymentElement.onReady', e);
        this.mounted = true;
    }

    onChange(e) {
        console.log('StripePaymentElement.onChange', e);
    }

    onFocus(e) {
        console.log('StripePaymentElement.onFocus', e);
        this.customOnFocus();
    }

    onLoadError(e) {
        console.log('StripePaymentElement.onLoadError');
    }

    onLoaderStart(e) {
        console.log('StripePaymentElement.onLoaderStart');
    }

}