/**
 * JavaScript for the donate page.
 *
 * Due to Google reCaptcha integration, this object is created manually inside this file (rather than being created by the loader)
 * and the instance exposed on the 'window' object. It needs to be accessible globally in the reCaptcha callback function.
 *
 * There are three different Stripe integrations used on this page, each one has a slightly different flow through
 * the code.
 * 1. Card Element, this is used for card single/repeat donations
 * 2. Payment Element, this is used for BACS direct debit repeat donations
 * 3. Express Checkout Element, this is used for single PayPal/Google Pay/Apple pay donations
 */
'use strict';

const AnalyticsEvent        = require('Scripts/common/analytics-event').default;
const getUserLocale         = require('Scripts/common/get-user-locale');
const Loqate                = require('Scripts/donate-form/loqate.js').default;
const stripeStyles          = require('Scripts/donate-form/stripe-styles');
const StripeExpressElements = require('Scripts/donate-form/stripe-express-elements').default;
const StripePaymentElement  = require('Scripts/donate-form/stripe-payment-element').default;
const StripeUtils           = require('Scripts/donate-form/stripe-utils');
const TomSelect             = require('Scripts/common/tom-select').default;
const { 
    getDonationDataFromUrl, 
    preFillDonationForm, 
    redirectToTapToDonateAppSuccessScreen, 
    redirectToTapToDonateAppFailureScreen 
} = require('Scripts/donate-form/tap-to-donate-web-donation');

module.exports = Donate;

// Global variable, used for enabling browser's back button
var settingHash = false;

// See comment at top of file for why this is here.
if($('[data-module-group="donate"]').length > 0) {
	window.donateInstance = new Donate();
}

// Steps
const STEP_REVIEW = 'review';

// Payment method types
const PAYMENT_METHOD_CARD = 'card';
const PAYMENT_METHOD_EXPRESS_CHECKOUT = 'express_checkout';
const PAYMENT_METHOD_PAYMENT_ELEMENT = 'payment_element';

function Donate() {
	var self = this;

    // Whether this form is loaded through the Tap to Donate app
    this.isTapToDonateWebDonation = false;

	// Constants
	this.fees = Number($("#donationFee").val());
	this.giftaid = 0.25;

	// If lots of errors then we disable Stripe elements
	this.errorCount = 0;

	// Holds a reference to StripeExpressElements, which manages Stripe's ExpressElements
	this.expressElements = null;

	// Holds a reference to Stripe's API object
	this.stripe = null;

	// Stripe shared utilites, shared between different pages
	this.stripeUtils = null;

	// Holds a reference to Stripe's card number field
	this.cardNumberEl = null;

	// This gets set when the user interacts with the express checkout button(s) (PayPal/Apple/Google Pay)
	// It gets set back to false when the user closes the express checkout interface(s).
	this.usedExpressCheckoutButton = false;

	// This gets set when the user interacts with the payment element (Direct debits)
	// It gets set back to false when the user wants to pay via card.
	this.usedPaymentElement = false;

	// Let's us know whether Google Recaptcha has loaded or not.
	this.recaptchaLoaded = false;

	// Donations over this amount require the user to verify its legitimacy
	this.verificationThreshold = parseInt($(document.body).data('verificationThreshold'), 10);

	this.init = function() {
		this.setupParsley();
		this.bindEventHandlers();
		this.initStripe();
		this.updateDonationTotal();
		this.resetUrlHash();
		this.setupSelects();
		this.setupLoqate(); // Needs to be after setupSelects
		this.determineGiftAidVisiblity();

        const { donation } = getDonationDataFromUrl(window.location.href);
        if (donation) {
            this.isTapToDonateWebDonation = true;
            preFillDonationForm(donation, this);
        }
	}

	this.resetUrlHash = function() {
		var windowLocation = window.location;
		var currentStep = $('.js-step.show').data('step');

		if(windowLocation.hash != currentStep){
			history.pushState("", document.title, windowLocation.pathname + windowLocation.search + '#' + currentStep);
		}
	}

	this.selectElements = function() {
		this.ui = {
			// Containers
			steps: $('.js-step'),
			buttonNext: $('.js-next-step'),
			buttonPrev: $('.js-prev-step'),
			// Hidden fields
			paymentMethodIdField: $('#sessionId'), // PaymentMethod ID gets saved as session_id on the DB
			paymentIntentIdField: $('#trackingId'), // PaymentIntent ID gets saved as trackingid on the DB
			giftAidInd: $('#giftaidInd'),
			recaptchaTokenField: $('#recaptchaToken'),
			recaptchaLoadedField: $('#recaptchaLoaded'),
			paymentMethodField: $('#paymentMethod'),
			// Amount screen
			amountField: $('#amount'),
			toggleAmountGroup: $('.js-set-amount-group'),
			coverFeesField: $('.js-cover-fees'),
			topupAmount: $('.js-topup-amount'),
			topupAmountPreview: $('.js-topup-amount-preview'),
			typeField: $('input[name="type"]'),
			repeatOptions: $('.js-repeat-options'),
			repeatDateField: $('#repeatDate'),
			repeatDayField: $('#repeatDay'),
			repeatMonthField: $('#repeatMonth'),
			repeatDay: $('.js-repeat-day'),
			repeatMonth: $('.js-repeat-month'),
			repeatInfo: $('.js-repeat-info'),
			// Details screen
			screenName: $('#name'),
			makeAnon: $('.js-make-anon'),
			phoneContainer: $('.js-phone-container'),
			phoneInput: $('.js-phone-input'),
			msgInput: $('#message'),
			presetMsgs: $('.js-preset-messages'),
			// Gift Aid screen
			donationTotalGiftaid: $('.js-donation-total-giftaid'),
			giftAidCheckbox: $('input[name="giftAid"]'),
			giftAidRemove: $('.js-remove-giftaid'),
			giftAidStatement: $('.js-giftaid-statement'),
			giftAidAnswer: $('.js-giftaid-statement-answer'),
			giftAidAddress: $('.js-giftaid-address'),
			giftAidAddressFields: $('.js-giftaid-address input, .js-giftaid-address select'),
			giftAidContainer: $(".js-giftaid-address-toggle"),
			// Confirmation screen
			giftAidNot: $('.js-giftaid-not'),
			giftAidAmount: $('.js-giftaid-amount'),
			// Payment method screen
			expressCheckoutContainer: $('.js-express-checkout'),
			paymentMethodStep: $('#js-step-payment-method'),
			paymentMethodSpinner: $('#js-payment-method-spinner'),
			paymentMethodDirectDebitBtn: $('.js-direct-debit-btn'),
			paymentMethodCardBtn: $('.js-card-btn'),
			// Card payment screen
			copyAddress: $('.js-copy-address'),
			billingCountry: $('#billing-country'),
			billingNameLabel: $('.js-billing-name'),
			billingStateText: $('.billing-state-text'),
			billingState: $('.js-billing-state'),
			billingZipText: $('.billing-zip-text'),
			billingZip: $('#billing-zip'),
			billingContainer: $(".js-billing-address-toggle"),
			donationTotal: $('.js-donation-total'),
			paymentTotal: $('.js-payment-total'),
			donateButton: $('.js-donate'),
			makeDonation: $('.js-make-donation'),
			paymentElementContainer: $('.js-payment-element'),
			cardElementsContainer: $('.js-card-elements'),
			directDebitText: $('.js-direct-debit-text'),
			cardPaymentText: $('.js-card-payment-text'),
			// Thank you screen
			thankyouScreen: $('#js-thank-you'),
			declinedScreen: $('#js-declined'),
			declinedMessage: $('.js-declined-message'),
			donationReference: $('.js-donation-ref'),
			// Success messages
			defaultSuccessMsg: $('.js-default-success-msg'),
			verifySuccessMsg: $('.js-verification-success-msg'),
			forms: {
				donation: $('.js-main-donation-form')
			}
		};

		this.repeatDonationPaymentMethods = {
			'card': this.ui.paymentMethodCardBtn,
			'payment-element': this.ui.paymentMethodDirectDebitBtn
		};

		this.singleDonationPaymentMethods = {
			'card': this.ui.paymentMethodCardBtn,
			'express-checkout': this.ui.expressCheckoutContainer
		};
	}

	this.setupParsley = function() {
		window.Parsley.addValidator('postcode', {
			validateString: function(value, countryTarget) {
				// Allow anything for non-GB/UK
				var country = $(countryTarget);
				if(country != null && country.val() != "GB") {
					return true;
				}
				// Pattern lifted from https://ideal-postcodes.co.uk/guides/postcode-validation
				var postcodePattern = /^[a-z]{1,2}\d[a-z\d]?\s*\d[a-z]{2}$/gi
				// Clean up our input
				var ucPostcode = value.trim().replace(" ", "");
				// Compare against postcode pattern
				var result = ucPostcode.match(postcodePattern)
				// If there is a match, return true, otherwise false
				return result !== null
			}
		});

		window.Parsley.addValidator('blocklist', {
			validateString: function(value, blocklistStr) {
				if(value == null || value.trim() == "" || blocklistStr == null || blocklistStr.trim() == "") return true
				var blocklist = blocklistStr.toLowerCase().trim().split('|');
				return !blocklistStr.includes(value.toLowerCase().trim());
			}
		});

		window.Parsley.addValidator('notemail', {
			validateString: function(value) {
				if(value == null || !value.includes('@')) return true;
				value = value.toLowerCase();
				return !(value.includes('.co') || value.includes('.org') || value.includes('.net') || value.includes('.uk'));
			}
		});
	}

	this.setupSelects = function() {
		var selectOpts = {
			disableAutocomplete: true
		}

		this.ui.selects = {
			billing: new TomSelect('#billing-country', selectOpts),
			giftAid: new TomSelect('#country', selectOpts),
		};
	}

	this.setupLoqate = function() {
		new Loqate({ input: $("#giftaid-loqate"), countryInput: this.ui.selects.giftAid, enterManuallyBtn: $("#js-manual-giftaid-address") });
		new Loqate({ input: $("#billing-loqate"), countryInput: this.ui.selects.billing, enterManuallyBtn: $("#js-manual-billing-address") });
	}

	this.determineGiftAidVisiblity = function() {
		if(!getUserLocale().isUkExpanded && this.ui.giftAidCheckbox.length){
			console.log("Remove Gift Aid section");
			$("#js-step-giftaid").attr("data-enabled", false); // .remove() doesn't fully work as self.ui.steps doesn't refresh for some reason
			this.ui.giftAidCheckbox.attr("disabled", true); // Remove from validation
			$(".js-giftaid-review").remove();
		}
	}

	this.bindEventHandlers = function() {
		this.selectElements();

		// Next buttons (prev button handled in enableDonationButton())
		this.ui.buttonNext.on('click', this.goToNextStep);

		// Amount screen
		this.ui.amountField.on('change', this.onAmountChanged);
		this.ui.coverFeesField.on('change', this.updateDonationTotal);
		this.ui.toggleAmountGroup.on('click', '.js-set-amount', this.toggleAmount);
		this.ui.typeField.on('change', this.onTypeChanged);
		this.ui.repeatDateField.on('change', this.onRepeatDateChanged);

		// Details screen
		this.ui.makeAnon.on('change', this.onToggleAnon);
		this.ui.msgInput.on('change keyup', this.onUpdateMsg);
		this.ui.presetMsgs.on('click', 'button', this.onClickPresetMsg);

		// Gift Aid screen
		this.ui.giftAidCheckbox.on('change', this.onToggleGiftAid);
		this.ui.giftAidAnswer.on('change', this.onGiftAidAnswer);
		this.ui.giftAidContainer.on('show.bs.collapse', this.onGiftAidContainerShown); 
		this.ui.giftAidRemove.on('click', this.onRemoveGiftAid);

		// Payment method screen
		this.ui.paymentMethodCardBtn.on('click', this.onClickPaymentMethodCardBtn.bind(this));
		this.ui.paymentMethodDirectDebitBtn.on('click', this.onClickPaymentMethodDirectDebitBtn.bind(this));

		// Payment screen
		this.ui.copyAddress.on('click', this.onCopyAddress);
		this.ui.billingCountry.on('change', this.onBillingCountryChanged);
		this.ui.billingContainer.on('show.bs.collapse', this.onBillingContainerShown); 

		// Other
		this.ui.forms.donation.on('keydown', this.onKeydown);

		// Bind form validation
		this.ui.forms.donation.parsley({
			excluded: ':disabled',
			errorsContainer: function(field) {
				var $el = field.$element;
				return $el.closest('.form-group').find('.error-container');
			}
		});

		// Scroll to first form error
		self.ui.forms.donation.parsley().on('form:error', (e) => {
			var firstError = $($(".js-step.show .parsley-errors-list li")[0]);
			var closestContainer = firstError.closest(".box__content");
			$("html, body").animate({scrollTop: (closestContainer.offset() || firstError.offset() || $("main").offset()).top - 50}, "slow");
		});

		$(window).bind('hashchange', this.onHashChanged);

		$(window).on('load', function() {
			if(typeof grecaptcha !== 'undefined') {
				grecaptcha.ready(function() {
					console.log('Donate.bindEventHandlers', 'Google recaptcha has been loaded.');
					this.recaptchaLoaded = true;
					this.ui.recaptchaLoadedField.val(this.recaptchaLoaded);
				}.bind(this));
			}
		}.bind(this));
	}

	this.onClickPaymentMethodCardBtn = function() {
		// Unlike the Express Checkout elements, there's no definitive way to determine that the user
		// has finished using the Payment Element (for example if they select DD, then go back and select card
		// payment). We can assume if they click this button they're making a card payment, and if they do go back
		// and select DD again, the onFocus method will take care of setting this back to the correct value.
		this.usedPaymentElement = false;

		this.ui.paymentElementContainer.hide();
		this.ui.directDebitText.hide();
		this.ui.cardPaymentText.show();
		this.ui.cardElementsContainer.show();

		this.ui.billingNameLabel.text('Name on card');
	}

	this.onClickPaymentMethodDirectDebitBtn = function() {
		this.ui.paymentElementContainer.show();
		this.ui.directDebitText.show();
		this.ui.cardPaymentText.hide();
		this.ui.cardElementsContainer.hide();

		this.ui.billingNameLabel.text('Account holder name');

		// Edge case: User enters partial card details, goes back to DD screen. In all likelihood, never going to happen, but if it
		// does we don't want it to display an incorrect state.
		// This function call hides Stripe's error container.
		this.toggleStripeError({});
	}

	this.initStripe = function() {
		console.log("Donate.initStripe");
		// stripeUtils contains the verify() method which handles all the verification interactions between the client, our server, and Stripe during the 3D secure verification process.
		self.stripeUtils = new StripeUtils({
			onSuccess: self.showThankyouScreen,
			onFail: self.showDeclinedScreen
		});

		self.stripe = self.stripeUtils.stripe;

		// CardElement integration
		self.mountStripeCardElements();

		// Express Checkout integration
		self.expressElements = new StripeExpressElements(self);
		
		// Payment Element integration
		this.stripePaymentElement = new StripePaymentElement(this, {
			onFocus: () => {
				this.usedPaymentElement = true;
			}
		});

		self.enableDonationButton();
	}

	this.mountStripeCardElements = function() {
		console.log("Donate.mountStripeCardElements");
		var elements = self.stripe.elements({
			fonts: stripeStyles.fonts
		});

		var appearance = stripeStyles.cardElementStyles;
		var hidePlaceholders = stripeStyles.hidePlaceholders;

		var elementClasses = {
			focus: 'focus',
			empty: 'empty',
			invalid: 'parsley-error'
		};

		$('.card-error').hide();

		var cardNumber = elements.create('cardNumber', {
			placeholder: hidePlaceholders ? '' : '1234 '.repeat(4),
			style: appearance,
			classes: elementClasses
		});

		this.cardNumberEl = cardNumber;

		cardNumber.mount('#card-number');

		cardNumber.on('change', function(event) {
			self.toggleStripeError(event)

			if(event.error) {
				self.errorCount ++;

				if(self.errorCount > 9) {
					cardNumber.unmount();
					self.disableDonationButton();
				}
			}
		});

		var cardExpiry = elements.create('cardExpiry', {
			placeholder: hidePlaceholders ? '' : 'MM / YY',
			style: appearance,
			classes: elementClasses
		});

		cardExpiry.mount('#card-expiry');

		cardExpiry.on('change', function(event) {
			self.toggleStripeError(event)
		});

		var cardCvc = elements.create('cardCvc', {
			placeholder: hidePlaceholders ? '' : 'CVC',
			style: appearance,
			classes: elementClasses
		});

		cardCvc.mount('#card-cvc');

		cardCvc.on('change', function(event) {
			self.toggleStripeError(event)
		});
	}

	this.onHashChanged = function(e) {
		// If we are setting the hash (through form buttons), we don't run this
		if(settingHash){
			settingHash = false;
			return;
		}

		var oe = e.originalEvent;
		var oldUrl = oe.oldURL;
		var newUrl = oe.newURL;

		var initialHash = self.ui.steps.first().data('step');
		var oldHash = typeof oldUrl != 'undefined' ? (oldUrl.includes("#") ? oldUrl.substring(oldUrl.indexOf('#')+1) : initialHash) : -1;
		var newHash = typeof newUrl != 'undefined' ? (newUrl.includes("#") ? newUrl.substring(newUrl.indexOf('#')+1) : initialHash) : -1;

		var oldHashPosition = self.getStepPosition(oldHash);
		var newHashPosition = self.getStepPosition(newHash);

		console.log("DonateForm.onHashChanged", "URL hash has changed from " + oldHash + " to " + newHash);

		if(newHashPosition != oldHashPosition && newHashPosition > -1) {
			var currentStep = $('.js-step.show');
			var btn = currentStep.find(newHashPosition < oldHashPosition ? self.ui.buttonPrev : self.ui.buttonNext);

			// If no buttons, we're on the success/failure screen - reload if going back as allows them to go back to start
			if(!btn.length) {
				window.location.reload();
				return;
			}

			btn.trigger('click');
			settingHash = false;
		}
	}

	this.resetRecaptcha = function() {
		console.log("Donate.resetRecaptcha");
		grecaptcha.reset();
		self.ui.recaptchaTokenField.val('');
	};

	// This gets called when the recaptcha token has been generated. Triggered by attempting a payment element/card donation
	this.onRecaptchaTokenGenerated = function(token) {
		console.log("Donate.onRecaptchaTokenGenerated", token);
		console.log('Used payment method: ', self.getUsedPaymentMethod());
		self.ui.recaptchaTokenField.val(token);
		
		// Payment element payments
		if(self.usedPaymentElement) {
			this.stripePaymentElement.makePayment().then(result => {
				if(result?.amendDirectDebitDetails) {
					grecaptcha.reset();
					console.log('Amending direct debit details');
					this.enableDonationButton();
				}
			});
			return;
		}

		// Card payments
		self.handleCardDonation();
	};

	this.extractBillingDetails = function() {
		return {
			"billing_details": {
				"email": $('input[name=email]').val(),
				"name": $('#billing-name').val(),
				"address": {
					"line1": $('#billing-address').val(),
					"city": $('#billing-city').val(),
					"state": $('#billing-state').val(),
					"postal_code": $('#billing-zip').val(),
					"country": $('#billing-country').val()
				}
			}
		};
	};

	this.handleCardDonation = function() {
		console.log("Donate.handleCardDonation");

		var billingDetails = this.extractBillingDetails();

		// Initial post to Stripe. This returns a paymentMethod.id token that is passed to our backend.
		self.stripe.createPaymentMethod("card", self.cardNumberEl, billingDetails)
			.then(function(result) {
				if (result.error) {
					// This is going to be an error where Stripe complains about the form inputs.
					self.toggleStripeError(result)
					self.enableDonationButton();
					self.resetRecaptcha();

					return;
				}
				self.submitPayment(result).fail(function() {
					self.enableDonationButton();
					self.resetRecaptcha();
				});
			});
	}

	this.onClickDonateButton = function(e) {
		console.log("Donate.onClickDonateButton");
		e.preventDefault();

		self.disableDonationButton();

		// Validate form input
		self.ui.forms.donation.parsley().validate();
		var isValid = self.ui.forms.donation.parsley().isValid();

		// If not valid, don't bother generating a recaptcha token as it could've expired by the time they re-submit after
		// fixing any errors.
		if(!isValid) {
			self.enableDonationButton();
			return;
		}

		if(this.usedPaymentElement) {
			console.log('Used payment element.');
			this.stripePaymentElement.validate().then(result => {
				console.log('Validation result', result);
				// This automatically updates Stripe's UI with errors
				if(result.error) {
					console.log('Stripe UI showing errors');
					this.enableDonationButton();
					return;
				} else {
					console.log('All valid!');
					grecaptcha.execute().then(() => {
						// this.onRecaptchaTokenGenerated() gets called after this finishes.
					}).catch(err => {
						console.error(err);
						self.enableDonationButton();
					});
				}
			});
			return;
		}

		grecaptcha.execute().then(function() {
			// this.onRecaptchaTokenGenerated() gets called after execute() finishes, which is where the Stripe token generation
			// and other bits happen. Inside donate/recaptcha.vm is where you define what happens after a token has been created.
		}).catch(function() {
			self.enableDonationButton();
		});
	}

	// This is called when Stripe complains about the card details inputs
	this.toggleStripeError = function(event) {
		console.log("Donate.toggleStripeError");
		var container = $("#stripe-error-container");
		if(event.error) {
			if(event.error.message.indexOf('You passed an empty string') > -1) {
				event.error.message = "Please complete all required fields";
			}

			container.find(".parsley-required").text(event.error.message);
			container.show();
		} 
		else {
			container.hide();
		}
	}

	// User submits the form containing their billing information
	// It returns a promise as each method that calls it needs to handle the outcome differently.
	this.submitPayment = function(result) {
		console.log("Donate.submitPayment", result);

		// todo: stripe-express-elements is doing something similar to this, maybe we can remove
		//       that so it's all in one place.
		if(result) {
			const { paymentMethod, paymentIntent } = result;
			const paymentMethodId = paymentMethod?.id ?? paymentIntent?.payment_method;
			self.ui.paymentMethodIdField.val(paymentMethodId);

			if(paymentIntent?.id) {
				self.ui.paymentIntentIdField.val(paymentIntent.id);
			}
		}

		// This field is used to determine what type of payment was made
		self.ui.paymentMethodField.val(self.getUsedPaymentMethod());

		// Initial AJAX request to create donation row in our database.
		return $.ajax({
			url: '/donate/make',
			type: 'POST',
			data: self.ui.forms.donation.serializeArray()
		}).then(function(resp) {
			console.log("Donate.submitPayment", "response", resp);
			$(".js-signup-btn").attr("href", "/signup?key="+(resp.donationSignupKey || ''));
			return self.stripeUtils.verify(resp);
		});
	}

	this.goToNextStep = function(e) {
		e.preventDefault();

		var currentStep = $(e.currentTarget).parents('.js-step').data('step');
		var nextStep = self.getNextStep(currentStep);

		console.log("Donate.goToNextStep", currentStep, nextStep);

		// Validate the first set of fields
		self.ui.forms.donation.parsley().validate({
			group: 'step-' + currentStep
		});

		var isValid = self.ui.forms.donation.parsley().isValid({
			group: 'step-' + currentStep
		});

		if(isValid) {
			// note(dan): I know this is hacky, but couldn't figure out a better place to put this code.
			// It needs to be called at the point in which you show the payment methods screen - you could call it when
			// the amount/type/payment element loads/express checkout loads, but I don't think that's any better. Easier
			// to have in one place.
			if(nextStep === STEP_REVIEW) {
				const availablePaymentMethods = self.getAvailablePaymentMethods();

				// If only one payment method option then we can skip the screen
				console.log("Checking number of repeat payment methods", Object.keys(availablePaymentMethods));
				self.ui.paymentMethodStep.attr("data-enabled", Object.keys(availablePaymentMethods).length > 1);

				self.showAvailablePaymentMethods();
			}

			$('#js-step-'+currentStep).collapse('hide');
			$('#js-step-'+nextStep).collapse('show');
			// Below uses currentStep instead of nextStep because the show/hide means position changes and anims means actions overlap
			self.scrollToTop();

			settingHash = true;
			window.location.hash = nextStep;

		} else { // This is here as we trigger this function when the user clicks browser forward button
			settingHash = true;
			window.location.replace('#'+currentStep);
		}
	}

	this.goToPrevStep = function(e) {
		e.preventDefault();

		var currentStep = $(e.currentTarget).parents('.js-step').data('step');
		var prevStep = self.getPrevStep(currentStep);

		console.log("Donate.goToPrevStep", currentStep, prevStep);

		$('#js-step-'+currentStep).collapse('hide');
		$('#js-step-'+prevStep).collapse('show');
		self.scrollToTop();

		settingHash = true;
		window.location.hash = prevStep;
	}

	this.getEnabledSteps = function() {
		return self.ui.steps.filter(":not([data-enabled='false'])");
	}

	this.getNextStep = function(currentStep) {
		var steps = self.getEnabledSteps();
		return $(steps[self.getStepPosition(currentStep) + 1]).data('step');
	}

	this.getPrevStep = function(currentStep) {
		var steps = self.getEnabledSteps();
		return $(steps[self.getStepPosition(currentStep) - 1]).data('step');
	}

	this.getStepPosition = function(stepName) {
		var targetStep = $("#js-step-"+stepName);
		return self.getEnabledSteps().index(targetStep);
	}

	// Manually enter a donation amount
	this.onAmountChanged = function() {
		var value = $(this).val();

		self.setAmountCss(value);

		self.updateDonationTotal();
	}

	// Choose a preset donation amount
	this.toggleAmount = function() {
		var value = $(this).data().value;

		self.setAmountCss($(this).data().uri);

		// Update input field + remove
		self.ui.amountField.val(value);

		// Remove any validation errors
		self.ui.amountField.parsley().reset();
		self.updateDonationTotal();
	}

	// Set the CSS classes for the amount boxes
	this.setAmountCss = function(value) {
		// Remove any active state
		self.ui.toggleAmountGroup.find('.js-set-amount').removeClass('btn-navy').addClass('btn-default');

		// Add active state
		self.ui.toggleAmountGroup.find('.js-set-amount-' + value).removeClass('btn-default').addClass('btn-navy');
	}

	// Change between single donation and monthly
	this.onTypeChanged = function(event) {
		console.log("onTypeChanged", event.target.value);

		const isSingleDonation = event.target.value === "SINGLE"

		// Change button styles
		$(self.ui.typeField).closest('.btn').removeClass('btn-navy').addClass('btn-outline-navy');
		$(this).closest('.btn').removeClass('btn-outline-navy').addClass('btn-navy');

		self.ui.repeatOptions.collapse('toggle');
		self.ui.repeatInfo.collapse('toggle');

		if(isSingleDonation) {
			var d = new Date();
			self.ui.repeatDateField.val(d.getDate());
			self.ui.repeatDateField.trigger('change');
		}

		self.ui.paymentMethodCardBtn.toggleClass("btn-green", isSingleDonation)
		self.ui.paymentMethodCardBtn.toggleClass("btn-default", !isSingleDonation)
	}

	this.onRepeatDateChanged = function(event) {
		console.log("Repeat date changed to " + this.value + " of the month");
		
		var selected  = $(this.options[this.selectedIndex]);
		var day       = selected.data('day');
		var ordinal   = selected.data('ordinal');
		var month     = selected.data('month');
		var monthName = selected.data('monthName');
		var isToday   = this.value == (new Date).getDate();

		// Update the hidden fields
		self.ui.repeatDayField.val(day);
		self.ui.repeatMonthField.val(month);

		// Change text and show/hide sections
		self.ui.repeatDay.text(ordinal);
		self.ui.repeatMonth.text(' ' + monthName);
		$('.js-repeat-schedule').text(selected.text());
		$('.js-repeat-date').text(ordinal + ' ' + monthName);
		$('.js-donated-now').toggle(isToday);
		$('.js-donated-future').toggle(!isToday);
	}

	// Toggle between first name and 'Anonymous'
	this.onToggleAnon = function(event) {
		if( $(this).is(":checked") ){
			self.ui.screenName.val('Anonymous');
		}
		else if (self.ui.screenName.val() == 'Anonymous') {
			self.ui.screenName.val($(this).data('default'));
		}
	}

	this.onUpdateMsg = function(event) {
		self.ui.presetMsgs.collapse(self.ui.msgInput.val().length ? 'hide' : 'show');
	}

	this.onClickPresetMsg = function(event) {
		self.ui.msgInput.val($(event.currentTarget).html());
	}

    // note: event is unused, but it accesses the clicked element with 'this'...
	this.onToggleGiftAid = function(event) {
		var giftAidSelected = $(this).val() == "Y";

		// Enable/disable GiftAid statements
		$(self.ui.giftAidAnswer).each(function() {
			$(this).attr("disabled", !giftAidSelected).attr("required", giftAidSelected).prop('checked', false);
		});

		// Show statement
		self.ui.giftAidStatement.collapse(giftAidSelected ? 'show' : 'hide');

		// Hide address if it's already been shown
		if(!giftAidSelected) {
			self.ui.giftAidAddress.collapse('hide');
			self.ui.giftAidAddressFields.attr('disabled',true).attr('required',false);
			self.ui.giftAidInd.val(false);
		}

		// Update review page
		self.ui.giftAidNot.html(giftAidSelected ? '' : 'not ')

	}

	this.onRemoveGiftAid = function(event) {
		console.log("Donate.onRemoveGiftAid");
		$("#giftAidN").attr("checked", true).trigger("change");
	}

	this.onGiftAidAnswer = function(event) {
		var unconfirmedAnswers = $(self.ui.giftAidAnswer).not(":checked").length;
		
		// If all answers have been confirmed, show the address fields, otherwise hide them
		if(unconfirmedAnswers == 0){	
			$(self.ui.giftAidAddressFields).each(function() {
				$(this).attr("disabled", false).attr("required", !$(this).hasClass('js-not-required'));
			});

			// Show address
			self.ui.giftAidAddress.collapse('show');

			// Set the Gift Aid hidden input to true
			self.ui.giftAidInd.val(true);
		}
		else {
			self.ui.giftAidAddress.collapse('hide');
			self.ui.giftAidAddressFields.attr('disabled',true).attr('required',false);
			self.ui.giftAidInd.val(false);
		}
	}

	this.onGiftAidContainerShown = function() {
		self.ui.forms.donation.parsley().reset();
		$("#giftaid-address-shown").val("true");
	};

	this.onCopyAddress = function(event) {
		console.log("Donate.onCopyAddress()");
		
		var addressFields = self.ui.giftAidAddressFields;

		addressFields.each(function() {
			var field     = $(this);
			var target    = $(field.data('copyTo'));
			var value     = field.val();
			var value2    = $(field.data('copyWith')).val() || "";
			var delimiter = field.data('copyDelimiter') || "";
			var selects   = self.ui.selects[field.data('copySelects')];

			if(value != "" && selects != null) {
				selects.ts.addItem(value);
			}

			if(value != "") {
				target.val(value + (value2 != "" ? delimiter + value2 : ""));
			}
		});

		var button = $(event.currentTarget);
		$(button.data('reveal')).collapse('show');
		$(button.data('hide')).collapse('hide');
	}

	this.onBillingCountryChanged = function(event) {
		if($(this).val() == 'US') {
			self.ui.billingStateText.text("State");
			self.ui.billingZipText.text("Zip code");
			self.ui.billingState.parsley().options.requiredMessage = "Please enter your state";
			self.ui.billingZip.parsley().options.requiredMessage = "Please enter your zip code";
		} 
		else {
			self.ui.billingStateText.text("County");
			self.ui.billingZipText.text("Postcode");
			self.ui.billingState.parsley().options.requiredMessage = "Please enter your county";
			self.ui.billingZip.parsley().options.requiredMessage = "Please enter your postcode";
		}
	}

	this.onBillingContainerShown = function() {
		self.ui.forms.donation.parsley().reset();
		$("#billing-address-shown").val("true");
	};

	// If Stripe approve the payment
	this.showThankyouScreen = function(response) {
		self.ui.steps.collapse('hide');
		self.ui.thankyouScreen.collapse('show');
		settingHash = true
		window.location.hash = 'success';

		// Show a slightly different success messages if the user has to verify their large donation.
		let amount = parseFloat(self.ui.amountField.val()) || 0;
		if(amount >= self.verificationThreshold) {
			self.ui.defaultSuccessMsg.hide();
			self.ui.verifySuccessMsg.show();
		}

		self.scrollToTop();

		// Analytics
		AnalyticsEvent.facebook('Donate');
		AnalyticsEvent.heap('Donation', {donationValue:amount});
		AnalyticsEvent.gtm('DonationSuccess', {donationValue:amount});

        if (self.isTapToDonateWebDonation) {
            redirectToTapToDonateAppSuccessScreen();
        }
        

		/*
		// TODO: Enable this once we are ready to start tracking donations
		// Will require the start event to also be fired (see RedEyeEvent.java)
		AnalyticsEvent.redeye({
			'nourl': 'donation-complete',
			'donation_comp_event': 'donation-start-event',
			'donation_comp_ref': response.donationId,
			'donation_comp_name': $("#js-entity").html(),
			'donation_comp_val': self.ui.amountField.val(),
			'donation_comp_type': self.ui.typeField.val() == "REPEAT" ? "Monthly" : "One-Off",
		});
		*/
	};

	// Response can be a few things:
	// - Our API response (DonationAjaxResponse)
	// - Stripe's Error object: https://docs.stripe.com/api/errors
	// - A native JS error
	this.showDeclinedScreen = function(response) {
		console.log('Donate.showDeclinedSecreen', response);

		// We only want to surface human-readable messages, not native JS error messages, so we need to filter
		// those out.
		let errorMsg = "There was a problem making the donation."

		if(response.stripeErrorMsg) {
			// Our API response
			errorMsg = response.stripeErrorMsg;
		} else if(response.type && response.message) {
			// Stripe Error object (native JS objects have a 'message' property, so we can't blindly use response.message)
			errorMsg = response.message;
		}

		self.ui.declinedMessage.text(errorMsg);

		// If there is a donation id, add it to the error message.
		if(response.donationId) {
			var donationRefString = self.ui.donationReference;
			var prefix = $(donationRefString).data('prefix') || '';
			donationRefString.text(prefix + response.donationId);
		}

		// Present the error message
		self.ui.steps.collapse('hide');
		self.ui.declinedScreen.collapse('show');
		settingHash = true
		window.location.hash = 'failed';
		self.scrollToTop();

		// Analytics
		AnalyticsEvent.gtm('DonationFailure', {conversionValue:self.ui.amountField.val()});
		AnalyticsEvent.heap('DonationFailure', {donationValue:self.ui.amountField.val()});

        if (self.isTapToDonateWebDonation) {
            redirectToTapToDonateAppFailureScreen();
        }
	}

	this.scrollToTop = function() {
		$("html, body").animate({scrollTop: $('.js-main-donation-form').offset().top}, "slow");
	}

	// Update the various on-screen amounts, plus the final payment value passed to Stripe
	this.updateDonationTotal = function() {
		var donationValue = parseFloat(self.ui.amountField.val()) || 0;

		// Payment value might have fees added which is separate to the donation
		var paymentValue = donationValue;

		// Update the normal total
		self.updateAmountText(self.ui.donationTotal, donationValue);

		// Update the top-up total and calculate the payment total
		if(self.ui.coverFeesField.is(':checked')){
			self.updateAmountText(self.ui.topupAmount, donationValue * self.fees);
			paymentValue = donationValue * (1 + self.fees);
		}
		else {
			self.updateAmountText(self.ui.topupAmount,0);
		}

		// This top-up amount is always shown regardless of fees being covered
		self.updateAmountText(self.ui.topupAmountPreview, donationValue * self.fees);

		// Update payment total
		self.updateAmountText(self.ui.paymentTotal, paymentValue);

		// Update the total with giftaid
		self.updateAmountText(self.ui.donationTotalGiftaid, donationValue * (1 + self.giftaid));

		// Update the giftaid amount
		self.updateAmountText(self.ui.giftAidAmount, donationValue * self.giftaid);

		// Needs to be a whole number. Will be rounded up to the nearest penny if applicable. e.g. 3458.4000000000005 => 3458
		var amountInPence = parseInt((paymentValue * 100).toFixed(0));

		// Set the payment amount on the ExpressElements, if we don't do this, the donation amount stays at what it was set to originally.
		if(self.expressElements) {
			self.expressElements.updateAmount(amountInPence)
		}

		// Show the phone input + make it required if this donation is over the threshold, we may need to call them to verify
		if(donationValue >= self.verificationThreshold) {
			self.ui.phoneContainer.show();
			self.ui.phoneInput.prop('required', true);
		} else {
			self.ui.phoneContainer.hide();
			self.ui.phoneInput.prop('required', false);
		}

		// Hack for Feathers Ball
		var tickets = self.ui.amountField.find(":selected").data("tables");
		$(".js-tickets").html(tickets + " ticket" + (tickets > 1 ? "s" : ""));
	}

	// Update an amount in the HTML. Includes data-prefix and data-suffix which helps when the amount may be shown or hidden
	this.updateAmountText = function(elements, value){
		$(elements).each(function() { 
			var prefix = $(this).data('prefix') || '';
			var suffix = $(this).data('suffix') || '';

			var strOptions = {
				style: 'currency',
				currency: 'GBP',
				minimumFractionDigits: 2,
				maximumFractionDigits: 2,
			};

			if(value > 0) { 
				$(this).html(prefix + value.toLocaleString('en-GB', strOptions) + suffix);
			}
			else {
				$(this).html('');
			}
		});
	}

	// Stop premature form submissions with enter but still allow enter inside textareas
	this.onKeydown = function(event) {
		var target = $(event.target);
		var keyCode = event.keyCode || event.which;

		if(keyCode === 13 && !$(document.activeElement).is('textarea')) {
			event.preventDefault();
		}
	}

	this.enableDonationButton = function() {
		console.log("Donate.enableDonationButton");
		this.ui.makeDonation.removeClass('disabled');
		this.ui.buttonPrev.removeClass('disabled');
		// Bind both form submit and button press to the click handler. This ensures form submits via
		// e.g. enter key presses are handled appropriately, rather than the default action, which is to do a POST page load
		this.ui.forms.donation.on("submit", this.onClickDonateButton.bind(self));
		this.ui.makeDonation.on("click", this.onClickDonateButton.bind(self));
		this.ui.buttonPrev.on('click', this.goToPrevStep);
	};

	this.disableDonationButton = function() {
		console.log("Donate.disableDonationButton");
		this.ui.makeDonation.addClass('disabled');
		this.ui.forms.donation.off("submit");
		this.ui.makeDonation.off("click");
		this.ui.buttonPrev.addClass('disabled');
		this.ui.buttonPrev.off("click");
	};

	// Simple helper to determine what payment method the user used based on the things they interacted with
	this.getUsedPaymentMethod = function() {
		console.log("Donate.getUsedPaymentMethod");

		if(self.usedExpressCheckoutButton) {
			return PAYMENT_METHOD_EXPRESS_CHECKOUT;
		}

		if(self.usedPaymentElement) {
			return PAYMENT_METHOD_PAYMENT_ELEMENT;
		}

		return PAYMENT_METHOD_CARD;
	};

	// Returns a map of payment method name => container of available payment methods
	this.getAvailablePaymentMethods = () => {
		console.log('Donate.getAvailablePaymentMethods');

		const type = $('input[name="type"]:checked').val();

        // Make sure wallet payment methods + PayPal donations are disabled as they probably won't work
        // in a browser embedded inside an app.
        if (this.isTapToDonateWebDonation) {
            console.log('Tap to Donate web donation - only card is supported.');
            const singleDonationPaymentMethods = { ...this.singleDonationPaymentMethods };
            delete singleDonationPaymentMethods['express-checkout'];
            return singleDonationPaymentMethods;
        }

		if(type === 'SINGLE') {
			const singleDonationPaymentMethods = { ...this.singleDonationPaymentMethods };

			// Only show Express Checkout element if it loaded
			if(!this.expressElements?.mounted) {
				console.log('Stripe Express Checkout element not mounted, express-checkout no longer a payment method.');
				delete singleDonationPaymentMethods['express-checkout'];
			}
			
			return singleDonationPaymentMethods;
		}

		const repeatDonationPaymentMethods = { ...this.repeatDonationPaymentMethods };

		// Don't allow direct debits for amounts over the verification threshold, no process exists for that yet.
		const amount = parseFloat(this.ui.amountField.val()) || 0;
		if(amount >= this.verificationThreshold) {
			console.log('Amount over verification threshold, payment-element no longer a payment method.');
			delete repeatDonationPaymentMethods['payment-element'];
		}

		// Only show direct debits if Stripe's Payment Element loaded
		if(!this.stripePaymentElement?.mounted) {
			console.log('Stripe Payment Element not mounted, payment-element no longer a payment method.');
			delete repeatDonationPaymentMethods['payment-element'];
		}
		
		return repeatDonationPaymentMethods;
	};

	this.hideAllPaymentMethods = ()  => {
		const allPaymentMethods = {
			...this.repeatDonationPaymentMethods,
			...this.singleDonationPaymentMethods
		};
		console.log('Donate.hideAllPaymentMethods', allPaymentMethods);

		Object.values(allPaymentMethods).forEach(container => {
			container.hide();
		});
	};

	this.showPaymentMethods = (paymentMethodToContainerMap) => {
		console.log('Donate.showPaymentMethods', paymentMethodToContainerMap);
		Object.values(paymentMethodToContainerMap).forEach(container => {
			container.show();
		});
	};

	// This method updates the payment method screen to show the payment methods that are available.
	this.showAvailablePaymentMethods = () => {
		console.log('Donate.showAvailablePaymentMethods');

		// First, hide everything, it makes it simpler when swapping between repeat/single and
		// when changing the donation amount as we don't need to know what's visible, we can
		// just show what needs to be shown.
		this.hideAllPaymentMethods();

		const availablePaymentMethods = this.getAvailablePaymentMethods();
		this.showPaymentMethods(availablePaymentMethods);
	};

	this.init();
}